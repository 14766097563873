<template>
  <div class="pagination-wrap">
    <span v-if="total > pageSize">共计{{ total }}条记录</span>
    <el-pagination
      background
      :layout="layoutConfig"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :page-size="pageSize"
      hide-on-single-page
      :total="total"
      :current-page="firstPage"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "paginationVue",
  props: {
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    firstPage: {
      type: Number,
      default: 1
    },
    layoutConfig:{
      type: String,
      default: "sizes, prev, pager, next, jumper"
    }
  },
  data() {
    return {};
  },
  methods: {
    handleCurrentChange(pageNum) {
      this.$emit("currentChange", pageNum);
    },
    handleSizeChange(pageSize){
      this.$emit("sizeChange", pageSize);
    }
  }
};
</script>

<style scoped></style>
