<template>
  <div class="main_content">
    <el-page-header
      class="page_section_card"
      @back="goBack"
      content="TikTok分类设置"
    ></el-page-header>
    <el-card>
      <section
        class="display_flex align_item_center justify_content_space_between page_section_card"
      >
        <el-button type="primary" @click="dialogVisible = true"
          >新建分类</el-button
        >
        <el-input
          style="width: 300px"
          placeholder="请输入您需要搜索的分类"
          v-model="postdata.name"
          class="input-with-select"
          clearable
          @keyup.enter.native="search"
        >
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </section>
      <el-alert
        class="page_section_card"
        :title="`共有${total}个分类`"
        type="success"
        :closable="false"
        show-icon
      >
      </el-alert>
      <el-table
        v-loading="loading"
        :data="list"
        stripe
        style="width: 100%"
        tooltip-effect="dark"
      >
        <el-table-column prop="name" label="分类名称" min-width="180">
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="margin-right: 10px"
              @click="editClassify(scope.row)"
              >编辑分类</el-button
            >
            <el-popconfirm
              title="确定删除该分类吗？"
              confirm-button-type="text"
              @confirm="delClassify(scope.row)"
            >
              <el-button type="text" slot="reference">删除分类</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <pagination-vue
        @sizeChange="sizeInit"
        @currentChange="init"
        :pageSize="postdata.page_num"
        :total="total"
        :firstPage="firstPage"
      />
    </el-card>
    <el-dialog
      :visible.sync="dialogVisible"
      width="500px"
      :title="`${addData.id ? '编辑分类' : '新增分类'}`"
    >
      <section>
        <el-form
          :model="addData"
          ref="form1"
          label-width="100px"
          @submit.native.prevent
          @keyup.enter.native="addClassify"
        >
          <el-form-item
            prop="name"
            label="分类名称："
            :rules="[{ required: true, message: '请输入分类名称' }]"
          >
            <el-input
              type="text"
              v-model="addData.name"
              placeholder="请输入分类名称"
            ></el-input>
          </el-form-item>
        </el-form>
      </section>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addClassify">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import paginationVue from "@/components/paginationVue.vue";
export default {
  components: {
    paginationVue,
  },
  data() {
    return {
      total: 0,
      firstPage: 1,
      loading: false,
      postdata: {
        name: "",
        page: 1,
        page_num: 10,
      },
      list: [],
      dialogVisible: false,
      addData: {
        name: "",
      },
    };
  },
  created() {
    this.init(1);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    search() {
      this.init(1);
    },
    sizeInit(size) {
      this.postdata.page_num = size || 10;
      this.init(1);
    },
    init(page) {
      this.postdata.page = page || this.firstPage;
      this.loading = true;
      this.axios(
        "get",
        `${this.$baseUrl}v1/TiktokUser/getClassifyList`,
        this.postdata
      ).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.list = res.data.data;
          this.total = res.data.total;
          this.firstPage = res.data.current_page;
        }
      });
    },
    editClassify(row) {
      this.dialogVisible = true;
      this.addData = JSON.parse(JSON.stringify(row));
    },
    delClassify(row) {
      console.log(row);
      this.axios("POST", `${this.$baseUrl}v1/TiktokUser/delClassify`, {
        id: row.id,
      }).then((res) => {
        if (res.code == 1) {
          this.search();
        }
      });
    },
    addClassify() {
      this.$refs["form1"].validate((valid) => {
        if (valid) {
          let url = `${this.$baseUrl}v1/TiktokUser/insertClassify`;
          if (this.addData.id) {
            url = `${this.$baseUrl}v1/TiktokUser/updateClassify`;
          }
          this.axios("POST", url, this.addData).then((res) => {
            if (res.code == 1) {
              this.dialogVisible = false;
              this.addData = {
                name: "",
              };
              this.search();
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>